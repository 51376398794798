import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {
  firestorePlugin
} from 'vuefire'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import VueMeta from 'vue-meta'

Vue.use(VueMeta)

const devConfig = {
  apiKey: "AIzaSyDwedKrlWWlFfRb7ArPT-PDx9vNCtz8Gk8",
  authDomain: "videoquest-f9551.firebaseapp.com",
  projectId: "videoquest-f9551",
  storageBucket: "videoquest-f9551.appspot.com",
  messagingSenderId: "127408812116",
  appId: "1:127408812116:web:6a453ea49b846a17973dc1",
  measurementId: "G-WNCJ907BFG"
};

const prodConfig = {
  apiKey: "AIzaSyA_2J0Yn_cjsNJqHexFa6VXdUaZ9gUHTuA",
  authDomain: "videoq-9f74f.firebaseapp.com",
  projectId: "videoq-9f74f",
  storageBucket: "videoq-9f74f.appspot.com",
  messagingSenderId: "150080115094",
  appId: "1:150080115094:web:e9b60d98d72a6bf2024db7"
};

const whiteConfig = {
  apiKey: "AIzaSyC4q0VXLWX9FBviPZRcQZgvY6p9V53o09E",
  authDomain: "videoq-white-320a7.firebaseapp.com",
  projectId: "videoq-white-320a7",
  storageBucket: "videoq-white-320a7.appspot.com",
  messagingSenderId: "967347198732",
  appId: "1:967347198732:web:85266184c836682a454729",
  measurementId: "G-2H9010J65H"
}

export const devCloudinaryConfig = {
  preset: "rhsiinlg",
  cloudName: "dter7pjsd",
  apiKey: "511144294813784",
  apiSecret: "s9mI3QW5ggCHZE2X20JazB_o0-A",
};

export const prodCloudinaryConfig = {
  preset: 'mixplovs',
  cloudName: 'dsc6tqvgh',
  apiKey: '195798294954868',
  apiSecret: 'mw-Y2Kb8CM7edkiBNf6Ay2AEmv4',
};

export const whiteCloudinaryConfig = {
  preset: 'mixplovs',
  cloudName: 'dsc6tqvgh',
  apiKey: '195798294954868',
  apiSecret: 'mw-Y2Kb8CM7edkiBNf6Ay2AEmv4',
}

const devCloudPaymentApi = "test_api_00000000000000000000001";
const prodCloudPaymentApi = "pk_2e47c6c7016794baab23783f2acf8"

let config = 'dev';
if (!process.env.VUE_APP_ENV || process.env.VUE_APP_ENV === 'dev') {
  config = 'prod'
} else if (process.env.VUE_APP_ENV === 'prod') {
  config = 'prod'
}
else if (process.env.VUE_APP_ENV === 'white') {
  config = 'white'
}

export const cloudPaymentApi = (config === 'dev' ? devCloudPaymentApi : prodCloudPaymentApi)
export const fb = firebase.initializeApp(config === 'dev' ? devConfig : config === 'prod' ? prodConfig : whiteConfig);
export const db = fb
  .firestore()
Vue.use(firestorePlugin)
export const cloudinaryConf = (config === 'dev' ? devCloudinaryConfig :  config === 'prod' ? prodCloudinaryConfig : whiteCloudinaryConfig)

export const emailIntegrationConf = 'https://hook.integromat.com/vkilzpmssui85e5e6v5ah14rkdwjajq6'

import VideoBackground from 'vue-responsive-video-background-player';
Vue.component('video-background', VideoBackground);

import FeatherIcon from '@/components/FeatherIcon.vue'

Vue.component(FeatherIcon.name, FeatherIcon)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
