import Vue from 'vue'
import Vuex from 'vuex'


import projects from './projects'
import leadForms from './lead-forms'
import integrations from './integrations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    'projects': projects,
    'lead-forms': leadForms,
    integrations,
  }
})
