import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/vq/:id',
    name: 'videoquest',
    component: () => import(/* webpackChunkName: "videoquest" */ '@/views/projects/Widget.vue'),
  },
  {
    path: '/direct/:id',
    name: 'direct-quest',
    component: () => import(/* webpackChunkName: "direct-quest" */ '@/views/projects/Direct.vue'),
  },
  {
    path: '/body/:id',
    name: 'body-quest',
    component: () => import(/* webpackChunkName: "direct-quest" */ '@/views/projects/Body.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
