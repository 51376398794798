/* eslint-disable */
import { db, emailIntegrationConf } from "@/main";
import firebase from "firebase/compat/app";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    integrations: {
      data: [],
      answer: {},
      status: false,
    },
    currentIntegration: null,
    availableIntegrations: {
      data: [],
      asnwer: {},
      status: false,
    },
    projectIntegration: {
    },
  },
  getters: {
    getIntegrations: (state) => {
      return state.integrations;
    },
    getCurrentIntegration: (state) => {
      return state.currentIntegration
    },
    getAvailableIntegrations: (state) => {
      return state.availableIntegrations
    },
    getProjectIntegrations: (state) => {
      return state.projectIntegration
    }
  },
  mutations: {
    SET_INTEGRATIONS: (state, payload) => {
      state.integrations = {
        data: payload.data,
        answer: payload.answer,
        status: true
      };
    },
    SET_AVAILABLE_INTEGRATIONS: (state, payload) => {
      state.availableIntegrations = {
        data: payload.data,
        answer: payload.answer,
        status: true
      }
    },
    REMOVE_INTEGRATION: (state, payload) => {
      const integrationToRemove = state.integrations.answer[payload].displayId;
      delete state.integrations.answer[payload];
      const data = state.integrations.data.filter(integration => {
        return integration.displayId !== integrationToRemove
      })
      state.integrations.data = data;
    },
    SET_CURRENT_INTEGRATION: (state, payload) => {
      state.currentIntegration = payload
    },
    SET_PROJECTS_INTEGRATIONS: (state, payload) => {
      const includedIntegrations = payload.data.filter(integration => integration.status)
      state.projectIntegration['webhook'] = includedIntegrations.filter(integration => integration.type === "Webhook");
      state.projectIntegration['email'] = includedIntegrations.filter(integration => integration.type === "Email");
      state.projectIntegration['google'] = includedIntegrations.filter(integration => integration.type === "googleAnalytics");
      state.projectIntegration['yandex'] = includedIntegrations.filter(integration => integration.type === "yandexMetrika");
      state.projectIntegration['facebook'] = includedIntegrations.filter(integration => integration.type === "facebookPixel");
    }
  },
  actions: {
    requestIntegrations: ({commit}) => {
      db.collection('users').doc(localStorage.getItem('userId')).get().then(user => {
        let request = db.collection('integrations');
        if (user.data().isAdmin) {
          request = db.collection('integrations')
        }
        else request = db.collection('integrations').where('userId', '==', localStorage.getItem('userId'))
        request.get().then(querysnapshot => {
          const resultData = [];
          const result = {};
          querysnapshot.forEach(integration => {
            resultData.push(integration.data());
            result[integration.id] = integration.data();
          });
          commit("SET_INTEGRATIONS", { data: resultData, answer: result });
          })
      });

      // db.collection('integrations').where('userId', '==', localStorage.getItem('userId')).get().then(snapshot => {
      //   const resultData = [];
      //   const result = {};
      //   snapshot.forEach(integration => {
      //     resultData.push(integration.data());
      //     result[integration.id] = integration.data();
      //   });
      //   commit("SET_INTEGRATIONS", { data: resultData, answer: result });
      // })
    },
    requestAvailableIntegrations: ({commit}, payload) => {
      db.collection('integrations')
        .where('userId', '==', payload)
        .where('status', '==', true)
        .get()
        .then(snapshot => {
          const resultData = [];
          const result = {};
          snapshot.forEach(integration => {
            resultData.push(integration.data());
            result[integration.id] = integration.data();
          });
          commit("SET_AVAILABLE_INTEGRATIONS", { data: resultData, answer: result });
        })
    },
    setIntegrationStatus: ({commit}, payload) => {
      db.collection('integrations').doc(payload.id).update({status: payload.status})
    },
    addIntegration: ({commit}, payload) => {
      let r = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
      const integration = {
        ...payload, 
        userId: localStorage.getItem('userId'), 
        date: firebase.firestore.FieldValue.serverTimestamp(), 
        status: true, 
        displayId: r,
      }
      new Promise((resolve, reject) => {
        db.collection('integrations').add(integration)
          .then(() => resolve())
          .catch((e) => reject(e))
      })
    },
    removeIntegration: ({commit}, payload) => {
      db.collection('integrations').doc(payload).delete().then(() => {
        console.log('TODO Remove form project')
        commit('REMOVE_INTEGRATION', payload)
      })
    },
    requestCurrentIntegration: ({commit}, payload) => {
      new Promise((resolve, reject) => {
        db.collection('integrations').doc(payload).get().then((ans) => {
          commit('SET_CURRENT_INTEGRATION', {...ans.data()});
          resolve();
        }).catch((e) => reject(e))
      }) 
    },
    editIntegration: ({commit}, payload) => {
      new Promise((resolve, reject) => {
        db.collection('integrations').doc(payload.id).update(payload.data).then(() => {
          resolve();
        }).catch(e => reject(e))
      })
    },
    LinkIntegrationToProject: ({commit}, payload) => {
      if (payload.status) {
        db.collection('projects').doc(payload.project).update({
          integrations: firebase.firestore.FieldValue.arrayUnion(payload.integration)
        })
      } else {
        db.collection('projects').doc(payload.project).update({
          integrations: firebase.firestore.FieldValue.arrayRemove(payload.integration)
        })
      }
    },
    requestProjectIntegrations: ({commit}, payload) => {
      return new Promise((resolve, reject) => {
        db.collection('integrations').where(firebase.firestore.FieldPath.documentId(), 'in', payload).get().then((snapshot) => {
          const answer = {};
          const data = [];
          snapshot.forEach(doc => {
            answer[doc.id] = doc.data();
            data.push(doc.data());
          })
          commit('SET_PROJECTS_INTEGRATIONS', {data, answer})
          resolve();
        })
      })
    },
    triggerIntegrations: ({commit}, payload) => {
      if (payload.integrations.webhook)
        payload.integrations.webhook.forEach(webhook => {
          const webhookUrl = webhook.url;
          if (process.env.VUE_APP_ENV !== 'white')
          axios({
            method: 'post',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            withCredentials: false,
            // transformRequest: [(data, headers) => {
            //   console.log(data)
            //   delete headers.post["Content-Type"]
            //   return data
            // }],
            url: webhookUrl,
            params: {...payload.data},
          })
          else {
            axios({
              method: 'post',
              headers: {
                'Content-Type': 'text/plain',
              },
              withCredentials: false,
              // transformRequest: [(data, headers) => {
              //   delete headers.post["Content-Type"]
              //   return data
              // }],
              url: webhookUrl,
              data: {...payload.data},
            })
          }
        })
      if (payload.integrations.email)
        payload.integrations.email.forEach(email => {
          const webhookUrl = emailIntegrationConf;
          axios({
            method: 'post',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            withCredentials: false,
            transformRequest: [(data, headers) => {
              delete headers.post["Content-Type"]
              return data
            }],
            url: webhookUrl,
            params: {...payload.data, Clientmail: email.Clientmail},
          })
        })
    }
  }
}
